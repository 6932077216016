.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}

.navbar {
  padding: 10px;
}

.navbar-nav .nav-item {
  margin-right: 10px;
}

.nav-link {
  font-size: 16px;
}

.btn-link {
  font-size: 16px;
}

.app-container {
  margin-top: 20px;
  padding: 20px;
  min-height: calc(100vh - 40px);
}

.bg-light {
  background-color: #f8f9fa;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
